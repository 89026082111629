import React, { useEffect, useState } from 'react';
import ReferralSearch from './ReferralSearch';
import { LoadingIndicator, TwoSwitch } from '../../lib/iprs-react-library/src';

const ReferralSearchWithPreCheck = ({api, ...remianingProps}) => {
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        setLoading(true);
        // A blank request just checks the caseID in the token to see
        // if we have already been referred to a clinic
        api.transact("PWPracticeSearch", {})
        .then(() => {
            setLoading(false);
        }).catch((error) => {
            if(/already referred/i.test(error.message)){
                setError("You have already been referred to a clinic. You do not need to do anything else.");
            }else{
                setError(error.message);
            }
            setLoading(false);
        });
    }, [api]);

    return (
        <>
            <TwoSwitch test={loading}>
                <LoadingIndicator test={loading} />
                <TwoSwitch test={error}>
                    <div>{error}</div>
                    <ReferralSearch api={api} {...remianingProps} />
                </TwoSwitch>
            </TwoSwitch>
        </>
    );
};

export default ReferralSearchWithPreCheck;